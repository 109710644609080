<template>
  <v-container class="my-6 py-6">
    <v-row 
      justify="center" 
      class="my-6 px-6">
      <marca-item 
        v-for="marca in marcas" 
        :key="marca.id" 
        :marca="marca" />
    </v-row>
  </v-container>
</template>

<script>
import { Api } from '@/api_utils/axios-base'
export default {
  metaInfo: {
      title: 'Marcas'
    },
  components: {
    marcaItem: () => import('./MarcaItem')
  },
  data() {
    return {
      marcas: []
    }
  },
  methods: {
    async getMarcas() {
      const response = await Api.get('/api/marcas/')
      this.marcas = await response.data
      // console.log("marcas: ", this.marcas);
    }
  },
  created() {
    this.getMarcas()
  }
}
</script>

<style>

</style>